h1, h2, h3, h4, h5, p, a{
  font-family: "Space Grotesk", sans-serif;
}
  
h1 {
  font-size: 54px;
  line-height: 68px;
  font-weight: 700;
}
  
h2{
  font-size: 46px;
  line-height: 58px;
  font-weight: 700;
  margin: 0 0 30px;
}
  
h3{
  font-size: 38px;
  line-height: 48px;
  font-weight: 700;
}
  
h4{
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
}
  
h5{
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  color: var(--secondary);
  margin: 0 0 10px;
}
  
p, li{
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

a {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
}
  
.container{
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  box-sizing: border-box;
}

.banner-top {
  padding: 80px 0;
}

.banner-text {
  max-width: 700px;
}
  
.banner-top .banner-text h1 {
  max-width: 700px;
}
  
.banner-top .banner-text h5 {
  margin: 0 0 10px;
}
  
.banner-top .banner-text h1 {
  color: var(--light);
  margin: 0;
}

.banner-img img{
  object-fit: cover;
  max-height: 580px;
  width: 100%;
  object-position: 0% 70%;
}

.testimonial {
  padding: 80px 0;
  text-align: center;
}

.testimonial-quote img {
  margin-bottom: 25px;
}

.quote-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quote-slider svg.svg-inline--fa.fa-chevron-left, .quote-slider svg.svg-inline--fa.fa-chevron-right {
  width: 30px;
  height: 30px;
}

.quote-text p {
  max-width: 900px;
  margin-bottom: 40px;
}

.quote-text h4 {
  margin: 0 0 10px;
}

.quote-text img {
  margin: 0;
}

.rating-stars svg.svg-inline--fa.fa-star{
  color: var(--secondary);
}

.carousel .control-dots{
  position: relative !important;
  margin: 15px 0 0 !important;
}

.carousel.carousel-slider {
  position: relative !important;
  display: flex !important;
  flex-direction: column-reverse !important;
}

.control-dots .dot {
  background: var(--gray) !important;
  width: 10px !important;
  height: 10px !important;
  box-shadow: none !important;
}

.control-dots .dot.selected {
  background: var(--primary) !important  ;
}

.cta {
  background-image: url('../public/CTA-image.png');
  background-size: cover;
  background-position: 0% 80%;
  position: relative;
  padding: 80px 0;
}

.cta-inner {
  text-align: center;
}

.cta-heading {
  max-width: 900px;
  margin: 0 auto;
}

.cta-heading h3 {
  margin: 0 0 30px;
  color: var(--light);
}

button.cta-contact-btn {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  font-family: 'Space Grotesk';
  padding: 15px 30px;
  border: none;
  background: var(--secondary);
}

button.cta-contact-btn:hover {
  background: var(--primary);
  color: var(--light);
}

/* ===== Home Page ===== */
.homepage .banner-top{
  padding-bottom: 0;
}

.banner-heading-cta {
  display: flex;
  justify-content: space-between;
  margin-bottom: -63px;
  gap: 70px;
}

.banner-heading-cta h1 {
  color: var(--light);
  margin: 0;
}

.right-box {
  max-width: 550px;
  z-index: 1;
}

.box-content {
  background: var(--secondary);
  padding: 50px;
}

.right-box .box-content h2{
  margin-bottom: 0;
}

.box-contact {
  display: flex;
  align-items: center;
}

.box-content svg.svg-inline--fa.fa-phone {
  width: 20px;
  height: 20px;
}

.box-contact h5 {
  margin: 0;
  padding-left: 10px;
}

.box-contact a{
  color: var(--primary);
}

button.box-btn {
  width: 100%;
  padding: 20px;
  background: var(--primary);
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  font-family: 'Space Grotesk';
  border: none;
  color: var(--light);
}

button.box-btn:hover{
  background: var(--gray);
  color: var(--primary);
}  
.three-col h4 {
  color: var(--light);
}
.three-col p {
  color: var(--gray);
}

.firstsection{
  background-color: #17272C;
}
.secondsection{
  background-color: #152328;
}
.thirdsection{
  background-color: #132024;
}
svg.svg-inline--fa.fa-circle-check{
  color: var(--secondary);
  padding-right: 10px;
}
li {
  list-style-type: none;
}
.wwd-list{
  padding: 0;
}
.wwd-list li{
  color: var(--gray);
  margin-bottom: 15px;
}
button.cta-contact-btn {
  color: #3e3a3a;
}
/* ========== About Page CSS start ========== */
  
.zig-zag-text-media {
  display: flex;;
}

.zig-zag-media, .zig-zag-rev-media {
  width: 50%;
}
  
.zig-zag-media img, .zig-zag-rev-media img {
  height: 100%;
  width: 100%;
}
  
.zig-zag-content {
  padding: 80px 300px 80px 60px;
  width: 50%;
}
  
.zig-zag .zig-zag-text-media .zig-zag-content h5 {
  margin: 0 0 10px;
}
  
.zig-zag .zig-zag-text-media .zig-zag-content h2 {
  color: var(--light);
  margin: 0 0 30px;
}
  
.zig-zag .zig-zag-text-media .zig-zag-content p{
  color: var(--gray);
}

ul.zig-zag-list {
  padding: 0;
  margin: 0;
}

.zig-zag .zig-zag-text-media .zig-zag-content ul.zig-zag-list li {
  color: var(--gray);
  margin-bottom: 15px;
  display: flex;
}
  
.zig-zag-rev-text-media {
  display: flex;
}

.zig-zag-rev-content {
  padding: 80px 60px 80px 300px;
  width: 50%;
}
  
.zig-zag-rev .zig-zag-rev-text-media .zig-zag-rev-content h5 {
  margin: 0 0 10px;
}
  
.zig-zag-rev .zig-zag-rev-text-media .zig-zag-rev-content h2 {
  color: var(--light);
  margin: 0 0 30px;
}
  
.zig-zag-rev .zig-zag-rev-text-media .zig-zag-rev-content p{
  color: var(--gray);
}
  
.zig-zag-rev .zig-zag-rev-text-media .zig-zag-rev-content ul {
  margin: 5px 0 0;
  padding-left: 0;
}
  
.zig-zag-rev .zig-zag-rev-text-media .zig-zag-rev-content ul.zig-zag-rev-list li {
  color: var(--gray);
  margin-bottom: 15px;
  display: flex;
}

.team {
  padding: 80px 0;
}

.team-member {
  box-shadow: 0 1px 15px rgb(0 0 0 / 10%);
  border-radius: 10px;
}

.member-img img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.team-cards {
  display: flex;
  justify-content: center;
  gap: 80px;
}

.member-social-media svg.svg-inline--fa.fa-instagram, .member-social-media svg.svg-inline--fa.fa-facebook, .member-social-media svg.svg-inline--fa.fa-whatsapp {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 10px;
  background: #192a30;
  color: white;
  border-radius: 30px;
  top: 0px;
}

.member-social-media svg.svg-inline--fa.fa-instagram:hover, .member-social-media svg.svg-inline--fa.fa-facebook:hover, .member-social-media svg.svg-inline--fa.fa-whatsapp:hover {
  background: var(--gray);
  color: var(--primary);
}

.member-details {
  text-align: center;
  margin-top: -20px;
  padding: 0 20px 20px;
}

.member-details h4 {
  margin: 5px 0 10px;
}

.member-details p {
  margin: 0;
}

/* ========== Aboutpage CSS end ========== */

/* ========== Contactpage CSS start ========== */

.contact-banner .banner-img img {
  object-fit: cover;
  max-height: 580px;
  width: 100%;
  object-position: 0% 85%;
}

.contact-page .homepage-aboutsection {
  display: flex;
  align-items: flex-start;
}

.contact-page .firstsection, .contact-page .secondsection, .contact-page .thirdsection {
  width: 100%;
  background: var(--primary);
  padding: 50px;
}

.contact-page .firstsection  img, .contact-page .secondsection img, .contact-page .thirdsection img {
  width: 50px;
  height: 50px;
}

.contact-page .firstsection h4, .contact-page .secondsection h4, .contact-page .thirdsection h4 {
  margin: 20px 0 15px;
}

.contact-page .firstsection p, .contact-page .secondsection p, .contact-page .thirdsection p {
  margin-top: 5px;
}

.contact-page .firstsection a, .contact-page .secondsection a, .contact-page .thirdsection a {
  font-weight: 400;
  color: var(--gray);
}

.contact-form {
  max-width: 800px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding: 80px 0;
}

input[type="text"], input[type="email"], textarea {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 14px 16px;
  margin: 0 0 15px;
  font-family: inherit;
  font-weight: 400;
  background-color: #f1f3f4;
  border: 0;
  height: auto;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border-color 0.2s ease-in-out;
  -o-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
}

button.submit-btn {
  width: 100%;
  padding: 15px 30px;
  font-family: 'Space Grotesk';
  font-weight: 700;
  border: none;
  letter-spacing: 2px;
  background: var(--secondary);
  color: var(--primary);
}

button.submit-btn:hover {
  background: var(--primary);
  color: var(--light);
}

.car-company-inner {
  text-align: center;
}

.company {
  display: flex;
  justify-content: center;
  gap: 100px;
}

.company-logo {
  display: flex;
  gap: 100px;
}

.company-logo img {
  max-height: 140px;
}

.car-company {
  padding: 80px 0;
}

/* ========== Contactpage CSS end ========== */

/* ==================== LAPTOP CSS here ==================== */
@media only screen and (max-width: 1440px) {

  h1 {
    font-size: 46px;
    line-height: 58px;
  }

  h2{
    font-size: 38px;
    line-height: 46px;
  }

  h3{
    font-size: 32px;
    line-height: 40px;
  }

  h4{
    font-size: 28px;
    line-height: 36px;
  }

  .zig-zag-content{
    padding: 80px 60px;
  }

  .zig-zag-rev-content {
    padding: 80px 60px;
  }
}


/* ==================== TABLET CSS here ==================== */
@media only screen and (max-width: 1024px) {

  h1{
    font-size: 36px;
    line-height: 46px;
  }

  h2{
    font-size: 32px;
    line-height: 40px;
  }

  h3{
    font-size: 28px;
    line-height: 36px;
  }

  h4{
    font-size: 24px;
    line-height: 30px;
  }

  .container{
    padding: 0 40px;
  }

  .banner-top{
    padding: 40px 0;
  }

  .banner-img img{
    max-height: 420px;
  }
  
  /* ===== Home Page ===== */
  .homepage-aboutsection{
    flex-direction: column;
  }

  .homepage-aboutsection div {
    padding: 30px !important;
  }

  .banner-heading-cta{
    flex-direction: column;
    gap: 20px;
  }

  .company {
    flex-direction: column;
    gap: 80px;
  }

  .company-logo {
    gap: 0;
    justify-content: space-around;
  }

  /* ===== About Page ===== */
  .zig-zag-text-media {
    display: flex;
    flex-direction: column;
}

  .zig-zag-rev-text-media {
    display: flex;
    flex-direction: column-reverse;
}

  .zig-zag-media, .zig-zag-content, .zig-zag-rev-media, .zig-zag-rev-content {
    width: 100%;
  }

  .zig-zag-content, .zig-zag-rev-content {
    padding: 40px;
  }

  .team-cards{
    gap: 50px;
  }

  /* ===== Contact Page ===== */
  .contact-page .firstsection, .contact-page .secondsection, .contact-page .thirdsection {
    padding: 30px;
  }

  .contact-form{
    padding: 40px 0;
  }
}

/* ==================== Mobile CSS here ==================== */
@media only screen and (max-width: 767px) {

  .container{
    padding: 0 20px;
  }

  .box-content{
    padding: 30px;
  }

  .banner-img img{
    max-height: 280px;
  }

  .company {
    gap: 40px;
  }

  .company-logo img {
    max-height: 70px;
  }

  .team {
    padding: 50px 0;
  }

  .team-cards{
    flex-direction: column;
    gap: 30px;
  }

  /* ===== About Page ===== */
  .zig-zag-content, .zig-zag-rev-content {
    padding: 30px 20px;
  }
  
}