@import url('https://fonts.googleapis.com/css2?family=Marvel:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --primary: #192a30;
  --secondary: #d1b096;
  --gray: #a0aeb3;
  --light: #ffffff;
  --dark: #0b0b0b;
}

.background {
  background-color: var(--primary);
}
.h-10 {
  height: 13vh;
}
.h-5 {
  height: 7vh;
}
.header {
  background: var(--primary);
  padding: 15px 0;
}
.top-flex {
  display: flex;
  justify-content: space-between;
}
.header-main {
  margin-left: 8rem;
  margin-right: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-main .logo-section {
  color: var(--light);
  font-weight: 600;
}
.header-main .contact-section {
  color: var(--light);
}
.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.icons {
  margin-right: 5px;
  align-items: center;
}
svg.svg-inline--fa.fa-envelope, svg.svg-inline--fa.fa-phone {
  width: 20px;
  height: 20px;
  padding: 10px;
  background: var(--secondary);
  border-radius: 10px;
}
.contact-section a{
  color: var(--light);
  font-weight: 500;
}
.email {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-main {
  display: flex;
  justify-content: right;
  align-items: center;
}
.bottom-nav {
  background-color: var(--gray);
}
.navbar-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 10px 0;
}

.navbar-list li {
  margin-right: 50px;
  font-weight: 500;
  cursor: pointer;
  color: var(--primary);
}
.navbar-list li a {
  color: var(--primary);
  text-decoration: none;
}

.navbar-list li:last-child {
  margin-right: 0;
}
.navbar-toggler {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: none;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler-icon {
  width: 30px;
  height: 3px; /* Adjusted height for three lines */
  background-color: var(--primary); /* Black color */
  display: block;
  position: relative;
}

/* Styling the lines */
.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px; /* Adjusted height for three lines */
  background-color: var(--primary); /* Black color */
  position: absolute;
}

.navbar-toggler-icon::before {
  top: -8px; /* Adjusted top position for the top line */
}

.navbar-toggler-icon::after {
  bottom: -8px; /* Adjusted bottom position for the bottom line */
}
.homepage-topsection {
  height: 35vh;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-gap: 20px; /* Adjust the gap between the columns */
}

/* Optional: Adjust styles for each section */
.homepage-topsection > div {
  padding: 20px;
  background-color: var(--primary);
}
.homepage-topsection > div:nth-child(2) {
  text-align: left;
  padding: 3rem;
}
.homepage-welcome {
  margin-left: 30rem;
  margin-top: 4rem;
}
.homepage-bottomsection {
  height: 65vh;
}
.homepage-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sidebar {
  display: none;
}
body::-webkit-scrollbar {
  width: 0;
}
body {
  scroll-behavior: smooth;
}

.homepage-topsection {
  background-color: var(--primary);
}
.homepage-welcome p {
  color: var(--secondary);
  margin: 0;
}
.homepage-welcome h1 {
  color: var(--light);
  line-height: 40px;
  margin: 0;
}
.homepage-booking {
  height: 20vh;
  width: 22vw;
  background-color: var(--secondary) !important;
  margin-top: 10rem;
}
.homepage-booking div p {
  padding: 0;
  margin: 0;
  font-weight: 600;
  margin-top: 10px;
}
.logo {
  width: 40%;
}
.coloredlogo {
  display: none;
}
.contact-block span {
  font-weight: 600;
  margin-left: 8px;
}
.homepage-aboutsection {
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage-aboutsection div {
  padding: 5rem;
}
.homepage-aboutsection div h3 {
  color: var(--light);
  font-weight: 400;
  font-size: 26px;
  line-height: 35px !important;
  margin: 1rem 0px 0px 0px;
  padding: 0;
}
.homepage-aboutsection div p {
  margin: 2rem 0px 0px 0px;
  color: var(--gray);
  font-size: 15px;
}
.homepage-journy {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
}
.react-icon {
  color: var(--secondary);
  margin-right: 1rem;
}
.homepage-journy-secondsection h3 {
  color: var(--light);
}
.homepage-journy-secondsection p {
  color: var(--gray);
}
.homepage-journy div img {
  width: 80%;
}

/* ========== Footer ========== */
.footer{
  background: var(--primary);
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
}

.footer-inner-1 {
  max-width: 360px;
}

.footer-inner-2 {
  max-width: 180px;
}

.footer-inner-3 {
  max-width: 290px;
}

img.footer-logo {
  max-width: 280px;
  margin-bottom: 15px;
}

.footer-inner p {color: #A0AEB3;margin-bottom: 15px;}

.footer-inner a {
  font-weight: 500;
  color: #A0AEB3;
}

.footer-inner h5 {
  color: white;
  margin-bottom: 30px;
}

ul.footer-list {
  padding: 0;
}

.footer-list li {
  margin-bottom: 15px;
}

.timing {
  display: flex;
  align-items: center;
}

svg.svg-inline--fa.fa-clock {
  width: 20px;
  height: 20px;
  color: #d1b096;
  margin-right: 10px;
}

.timing p {
  margin-bottom: 0;
}

.footer-contact svg.svg-inline--fa.fa-envelope, .footer-contact svg.svg-inline--fa.fa-phone {
  width: 20px;
  height: 20px;
  padding: 0;
  color: var(--secondary);
  background: none;
  margin-right: 10px;
}

.mail-sec {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.call-sec {
  display: flex;
  margin-bottom: 15px;
}

.mobile-numbers {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.social-media {
  display: flex;
  gap: 20px;
}

.social-media svg.svg-inline--fa.fa-instagram, .social-media svg.svg-inline--fa.fa-facebook, .social-media svg.svg-inline--fa.fa-whatsapp {
  width: 20px;
  height: 20px;
  margin: 0px;
  padding: 10px;
  background: var(--secondary);
  color: var(--primary);
  border-radius: 10px;
  top: 0px;
}

.social-media svg.svg-inline--fa.fa-instagram:hover, .social-media svg.svg-inline--fa.fa-facebook:hover, .social-media svg.svg-inline--fa.fa-whatsapp:hover {
  background: var(--gray);
  color: var(--light);
}

.footer-copyright p {
  color: var(--gray);
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid var(--gray);
}

.carousel .slider-wrapper {
  max-width: 900px;
}

.counter-inner {
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
}

.counter-box {
  background: aliceblue;
  padding: 40px;
  display: flex;
  gap: 40px;
  align-items: center;
  background: var(--light);
  border-top: 5px solid var(--secondary);
  box-shadow: 0 1px 15px rgb(0 0 0 / 10%);
}

.counter-number span {
  font-family: "Marvel", sans-serif;
  font-size: 85px;
  font-weight: 700;
  line-height: 85px;
  color: var(--primary);
}

.counter-details p{
  font-weight: 500;
  margin: 0px;
  color: var(--secondary);
}

.counter-details svg {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  color: var(--secondary);
}

/*===== Car slider =====*/

.vehical-slider{
  padding: 80px 0;
}

.car-slider {
  display: flex;
  gap: 100px;
}

@media only screen and (max-width: 1024px) {
  .header {
    background: var(--primary);
    padding: 10px 0;
    border-bottom: 5px solid var(--gray);
  }

  .logo {
    width: 35%;
  }

  .mobile-header {
    display: block !important;
}

.mobile-header ul {
    display: block;
    margin: 20px 0;
}

.mobile-header ul li {
    margin: 0 0 20px;
}

  .footer-inner{
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .footer-inner-1, .footer-inner-2, .footer-inner-3, .footer-inner-4 {
    text-align: center;
  }

  .timing {
    justify-content: center;
  }

  .footer-contact{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-copyright p{
    text-align: center;
  }

  .counter-inner {
    justify-content: center;
    gap: 50px;
  }

  .counter-box {
    gap: 20px;
    flex-direction: column;
    text-align: center;
  }

  .navbar-toggler{
    display: block;
  }

  .navbar-toggler-icon{
    background-color: var(--light);
  }

  .navbar-toggler-icon::before{
    background-color: var(--light);
  }

  .navbar-toggler-icon::after{
    background-color: var(--light);
  }

  .contact-section {
    display: none;
  }
  .navbar-main {
    display: none;
  }

  .vehical-slider{
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-nav.mw-100 {
    background: var(--light);
  }
  .header-main {
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-main .logo-section {
    color: var(--dark);
    font-size: 2rem;
    font-weight: 600;
  }

  .h-10 {
    height: 10vh;
  }
  .counter-inner {
    flex-direction: column;
    gap: 30px;
  }

  .car-slider {
    flex-direction: column;
    gap: 30px;
  }
}
